interface PlatformItem {
  name: string;
  icon: string;
  desc?: string;
  link: string;
}

/* eslint-disable */
const platformList = [
  {
    name: 'BscScan',
    icon: require('../assets/images/home/bsc-scan.png'),
    link: "https://bscscan.com/token/0xd23891FC1A515A88C571064637502e3766819e2d"
  },
  {
    name: 'Pancakeswap',
    icon: require('../assets/images/home/pancake-swap.png'),
    link: "https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xd23891FC1A515A88C571064637502e3766819e2d"
  },
  {
    name: 'CoinMarketCap',
    icon: require('../assets/images/home/CoinMarketCap.png'),
    link: "https://coinmarketcap.com/currencies/neco-fun/"
  },
  {
    name: 'CoinGecko',
    icon: require('../assets/images/home/CoinGecko.png'),
    link: "https://www.coingecko.com/en/coins/neco-fun"
  },
];
/* eslint-disable */

const bannerList = [
  'neco-fishing',
  'neco-fun'
]

export {
  bannerList,
  PlatformItem,
  platformList,
};
