/* eslint global-require: "off" */
export interface NecoFeature {
  name: string;
  img: string;
}
export const necoFeatures = [
  {
    name: 'necoToken',
    img: require('../../assets/images/economics/neco/features/neco.png'),
  },
  {
    name: 'stakeForMining',
    img: require('../../assets/images/economics/neco/features/mining.png'),
  },
  {
    name: 'shareEarn',
    img: require('../../assets/images/economics/neco/features/earn.png'),
  },
  {
    name: 'voteToManage',
    img: require('../../assets/images/economics/neco/features/vote.png'),
  },
];

export interface TokenProportion {
  name: string;
  iconColor: string;
}
export const tokenProportion = [
  {
    name: 'teDesc1',
    iconColor: '#6e84f1',
  },
  {
    name: 'teDesc2',
    iconColor: '#fed776',
  },
  {
    name: 'teDesc3',
    iconColor: '#a1b5fc',
  },
  {
    name: 'teDesc4',
    iconColor: '#2476e1',
  },
  {
    name: 'teDesc5',
    iconColor: '#6e84f1',
  },
];
