export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Tokenomics"])},
        "necoDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The total amount of NECO tokens is fixed, and all NECO holders are shareholders of the project. NECO token holders will enjoy discounts on trading fees and 30% of earnings from products, and will have rights to govern and vote on the platform, etc."])},
        "maxSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max supply"])},
        "circulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulating Supply"])},
        "contractAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Address"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading Slippage"])},
        "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
        "necoToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Token"])},
        "stakeForMining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy Staking & Farming"])},
        "shareEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Platform Earnings"])},
        "voteToManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governance & Vote"])},
        "tokenEconomics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenomics"])},
        "teDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2% This part has been used to support 3 Airdrop events held previously and was used to expand NECO FUN communities."])},
        "teDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% This part was used for NECO token fair launch (with no private sale nor whitelist presale). NECO FUN team will focus on the product. "])},
        "teDesc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15% This part will be used for product development which is relatively costy, and will be distributed to NECO FUN team members."])},
        "teDesc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5% This part of will be used for NECO FUN project marketing to expand consumer awareness (unlock at any time if needed)."])},
        "teDesc5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["68% This part, the largest protion will be used for staking & farming and will be distributed to all NECO holders."])}
      },
      "zh": {
        "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币学"])},
        "necoDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币总量固定，持有者即为项目股东。持有NECO代币不仅能够减免产品手续费，同时也享受产品30%的收益分红，并具有治理，投票等权限。"])},
        "maxSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大供应量"])},
        "circulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["流通量"])},
        "burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已燃烧"])},
        "contractAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约地址"])},
        "slippage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买卖滑点"])},
        "feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["功能"])},
        "necoToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台母币"])},
        "stakeForMining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["享受质押挖矿"])},
        "shareEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享平台收益"])},
        "voteToManage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投票治理权限"])},
        "tokenEconomics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO代币经济学"])},
        "teDesc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2% 我们已经举行了超过3次的空投活动，这部分代币用于了扩大NECO FUN的社区。"])},
        "teDesc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10% NECO代币没有私募，没有白名单预售，10%用于了公平发射，项目方将会把更多精力放在产品上。"])},
        "teDesc3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15% 产品开发是一个比较耗费资金的部分，15%将会用于产品的研发，发放给NECO FUN的team成员。"])},
        "teDesc4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5% 这部分代码将会用于宣传NECO FUN项目，扩大项目知名度。 （如有需要随时解锁）"])},
        "teDesc5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["68% 一大部分的NECO代币将会是通过质押挖矿的形式分发给大家。"])}
      }
    }
  })
}
